<template>
  <Sidebar
    v-model:visible="visible"
    :pt="{
      header: {
        class: 'justify-content-between'
      },
      content: {
        class: 'flex'
      }
    }"
    header="test"
    position="right"
    @update:visible="onClose"
  >
    <template #header>
      <div class="flex align-items-center">
        🧙✨
        <strong>{{ $t('portal_ticketing_ai_chatbot_title') }}</strong>
        <Tag class="ml-2" severity="secondary" value="Alpha"></Tag>
      </div>
    </template>
    <ChatBot />
  </Sidebar>
</template>

<script setup lang="ts">
import Sidebar from 'primevue/sidebar';
import { useAiChatbotSidebar } from '@/composables/sidebars/use-ai-chatbot-sidebar';
import ChatBot from '@/components/chat/ChatBot.vue';
import Tag from 'primevue/tag';
const { visible, hide } = useAiChatbotSidebar();

async function onClose() {
  hide();
}
</script>
