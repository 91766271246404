<template>
  <FieldArray :name="name" :rules="rules">
    <!-- Select Pricing Categories -->
    <MultiSelect
      v-model="selectedPricingCategories"
      filter
      display="chip"
      :options="options"
      option-label="display_name"
      :placeholder="placeholder"
      :disabled="disabled"
      @change="onChange"
    />
    <!-- Default Pricing -->
    <PricingCategoryPrices
      v-if="selectedPricingCategories.length"
      :fields="fields"
      :pricing-categories="selectedPricingCategories"
      :name="name"
      :disabled="disabled"
    />
    <!-- Later Pricing Scales -->
  </FieldArray>
</template>

<script setup lang="ts">
import { FieldArray, useFieldArray } from 'vee-validate';
import { toRefs, type PropType, computed, ref, watch } from 'vue';
import MultiSelect, { type MultiSelectChangeEvent } from 'primevue/multiselect';
import type {
  PricingCategoriesReadResponsePricingCategoriesInner,
  PricingByIdReadResponsePricingPricingCategoryPricesInner
} from '@openapi/models';
import PricingCategoryPrices from '@/components/pricing/PricingCategoryPrices.vue';

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: 'required'
  },
  options: {
    type: Array as PropType<PricingCategoriesReadResponsePricingCategoriesInner[]>,
    required: true,
    default: () => []
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  }
});

const { name, placeholder, options, rules } = toRefs(props);
const { replace, fields } =
  useFieldArray<PricingByIdReadResponsePricingPricingCategoryPricesInner>(name);

const selectedPricingCategories = ref<PricingCategoriesReadResponsePricingCategoriesInner[]>([]);

// Selected pricing categories (during editing)
const selectedPricingCategoriesPrices = computed(() => {
  const selected = fields.value.map((field) => field.value.pricing_category_id);
  return options.value.filter((option) => selected.includes(option.id));
});

watch(selectedPricingCategoriesPrices, (value) => {
  selectedPricingCategories.value = value;
});

// We need to keep track of the pricing categories that are selected
// and then update the model value with the correct pricing category id
// We also need to keep the current value if a user has added the price
const onChange = async ({ value: modelValue }: MultiSelectChangeEvent) => {
  const payload = [];

  for (const value of modelValue) {
    let priceValue = 0;
    let id = '';

    // We need to keep the current value if it exists
    const currentValue = fields.value.find((item) => item.value.pricing_category_id === value.id);
    if (currentValue && currentValue.value) {
      priceValue = currentValue.value.value;
    }
    // If updating the id will be present
    if (currentValue && currentValue.value && currentValue.value.id) {
      id = currentValue.value.id;
    }

    payload.push({
      id,
      pricing_category_id: value.id,
      value: priceValue
    });
  }
  replace(payload);
};
</script>
