<template>
  <div class="flex align-items-center">
    <Field v-slot="{ value, handleChange }" :name="name" :rules="rules">
      <Checkbox :model-value="value" :binary="binary" @update:model-value="handleChange"></Checkbox>
    </Field>
    <label :for="name" class="ml-2">{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs } from 'vue';
import Checkbox from 'primevue/checkbox';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true,
    default: ''
  },
  binary: {
    type: Boolean,
    required: false,
    default: true
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  helpText: {
    type: String,
    required: false,
    default: null
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  placeholder: {
    type: String,
    required: false,
    default: null
  }
});

const { name, binary, rules, label } = toRefs(props);
</script>
