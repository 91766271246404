/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CustomizationSettingsReadResponseCustomizationSettings
 */
export interface CustomizationSettingsReadResponseCustomizationSettings {
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  logo_file_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  splash_screen_file_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  primary_color?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  primary_color_hover?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  primary_color_pressed?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  font_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  font_family?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  font_size?: string | null;
  /**
   *
   * @type {string}
   * @memberof CustomizationSettingsReadResponseCustomizationSettings
   */
  border_radius?: string | null;
}

/**
 * Check if a given object implements the CustomizationSettingsReadResponseCustomizationSettings interface.
 */
export function instanceOfCustomizationSettingsReadResponseCustomizationSettings(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function CustomizationSettingsReadResponseCustomizationSettingsFromJSON(
  json: any
): CustomizationSettingsReadResponseCustomizationSettings {
  return CustomizationSettingsReadResponseCustomizationSettingsFromJSONTyped(json, false);
}

export function CustomizationSettingsReadResponseCustomizationSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CustomizationSettingsReadResponseCustomizationSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    logo_file_id: !exists(json, 'logo_file_id') ? undefined : json['logo_file_id'],
    splash_screen_file_id: !exists(json, 'splash_screen_file_id')
      ? undefined
      : json['splash_screen_file_id'],
    id: !exists(json, 'id') ? undefined : json['id'],
    primary_color: !exists(json, 'primary_color') ? undefined : json['primary_color'],
    primary_color_hover: !exists(json, 'primary_color_hover')
      ? undefined
      : json['primary_color_hover'],
    primary_color_pressed: !exists(json, 'primary_color_pressed')
      ? undefined
      : json['primary_color_pressed'],
    font_url: !exists(json, 'font_url') ? undefined : json['font_url'],
    font_family: !exists(json, 'font_family') ? undefined : json['font_family'],
    font_size: !exists(json, 'font_size') ? undefined : json['font_size'],
    border_radius: !exists(json, 'border_radius') ? undefined : json['border_radius']
  };
}

export function CustomizationSettingsReadResponseCustomizationSettingsToJSON(
  value?: CustomizationSettingsReadResponseCustomizationSettings | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    logo_file_id: value.logo_file_id,
    splash_screen_file_id: value.splash_screen_file_id,
    id: value.id,
    primary_color: value.primary_color,
    primary_color_hover: value.primary_color_hover,
    primary_color_pressed: value.primary_color_pressed,
    font_url: value.font_url,
    font_family: value.font_family,
    font_size: value.font_size,
    border_radius: value.border_radius
  };
}
