<template>
  <Field v-slot="{ value, handleChange }" :name="name" :rules="rules">
    <Dropdown
      :filter="optionFilter"
      :model-value="value"
      :options="options"
      :option-label="optionLabel"
      :option-value="optionValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :show-clear="showClear"
      @update:model-value="handleChange"
    ></Dropdown>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs, type PropType } from 'vue';
import Dropdown from 'primevue/dropdown';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true,
    default: ''
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  options: {
    type: Array as PropType<{ label: string; value: string }[]>,
    required: true,
    default: () => []
  },
  optionLabel: {
    type: String,
    required: false,
    default: null
  },
  optionValue: {
    type: String,
    required: false,
    default: null
  },
  optionFilter: {
    type: Boolean,
    required: false,
    default: true
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  helpText: {
    type: String,
    required: false,
    default: null
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  showClear: {
    type: Boolean,
    required: false,
    default: false
  }
});
const { name, placeholder, optionLabel, optionValue, optionFilter, options, rules, showClear } =
  toRefs(props);
</script>
