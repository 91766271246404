<template>
  <GetYourGuideLogo class="logo-spacing mx-3" />
  <ul class="layout-menu p-1">
    <AppNavItem
      v-for="(routeConfig, i) in mainMenuRoutesBasedOnPermissions"
      :key="i"
      :route-config="routeConfig"
      :index="i"
    ></AppNavItem>
  </ul>
</template>

<script setup lang="ts">
import GetYourGuideLogo from '@/components/GetYourGuideLogo.vue';
import AppNavItem from './AppNavItem.vue';
import useRouteHandling from '@/composables/use-route-handling';

const { mainMenuRoutesBasedOnPermissions } = useRouteHandling();
</script>
<style lang="scss" scoped>
.logo-spacing {
  height: 5rem;
}
.layout-menu {
  height: 100vh;
  overflow-y: auto;
}

.layout-user {
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 5rem;
  &:hover {
    background-color: var(--surface-hover);
  }
}
</style>
