<template>
  <li class="nav-item">
    <router-link :to="{ name: routeConfig.name }" aria-current-value="page">
      <Button class="nav-button" text severity="secondary" :label="label" :icon="icon" />
    </router-link>
  </li>
</template>

<script setup lang="ts">
import { computed, toRefs, type PropType } from 'vue';
import Button from 'primevue/button';
import type { RouteRecordRaw } from 'vue-router';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  routeConfig: {
    type: Object as PropType<RouteRecordRaw>,
    required: true,
    default: () => ({})
  }
});

const { routeConfig } = toRefs(props);
const { t } = useI18n();
const label = computed(() => t(routeConfig.value.meta?.label as string));
const icon = computed(() => routeConfig.value.meta?.icon as string);
</script>

<style lang="scss" scoped>
.router-link-active {
  button {
    background-color: rgba(96, 125, 139, 0.16) !important;
  }
}
.nav-button {
  text-align: start;
}
</style>
