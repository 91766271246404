<template>
  <Field v-slot="{ value, handleChange }" :name="name" :rules="rules">
    <Editor
      :model-value="value"
      :placeholder="placeholder"
      :editor-style="editorStyle"
      @update:model-value="handleChange"
    >
      <template #toolbar>
        <span class="ql-formats">
          <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
          <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
          <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
          <button v-tooltip.bottom="'Strikethrough'" class="ql-strike"></button>
          <button v-tooltip.bottom="'Link'" class="ql-link"></button>
        </span>
        <span v-tooltip.bottom="'Text color & Background color'" class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>
        <span class="ql-formats">
          <button v-tooltip.bottom="'Ordered list'" class="ql-list" value="ordered"></button>
          <button v-tooltip.bottom="'Bullet list'" class="ql-list" value="bullet"></button>
          <button v-tooltip.bottom="'Clear formatting'" class="ql-clean"></button>
        </span>
      </template>
    </Editor>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs } from 'vue';
import Editor from 'primevue/editor';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  editorStyle: {
    type: String,
    required: false,
    default: 'height: 200px'
  }
});

const { name, placeholder, rules } = toRefs(props);
</script>
<style scoped lang="scss">
:deep(.p-editor-content) {
  font-family: inherit;
  font-size: inherit;
}
</style>
