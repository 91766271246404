<template>
  <div class="formgrid grid">
    <div class="field" :class="[canBeNegative ? 'col-12 md:col-4' : 'col-12 md:col-6']">
      <label for="hours" class="p-sr-only">{{ t('portal_reservation_cutoff_hours_label') }}</label>
      <InputNumber
        id="hours"
        class="w-full"
        :input-class="'w-full'"
        :model-value="localCutoffTimeNumericFormat.hours"
        :min="0"
        :placeholder="t('portal_reservation_cutoff_hours_label')"
        :suffix="' ' + t('portal_reservation_cutoff_hours_label')"
        @update:model-value="onChangeHours($event)"
      />
    </div>
    <div class="field" :class="[canBeNegative ? 'col-12 md:col-4' : 'col-12 md:col-6']">
      <InputNumber
        id="minutes"
        class="w-full"
        :input-class="'w-full'"
        :placeholder="t('portal_reservation_cutoff_minutes_label')"
        :suffix="' ' + t('portal_reservation_cutoff_minutes_label')"
        :min="0"
        :max="60"
        :model-value="localCutoffTimeNumericFormat.minutes"
        @update:model-value="onChangeMinutes($event)"
      />
    </div>
    <div v-if="canBeNegative" class="field col-12 md:col-4">
      <label for="sign" class="p-sr-only">{{ t('Sign:') }}</label>
      <Dropdown
        id="sign"
        class="w-full"
        :options="dropdownSignOptions || defaultSignOptions"
        :model-value="signValue"
        option-label="name"
        option-value="id"
        @update:model-value="onChangeSign($event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import { useField } from 'vee-validate';
import { computed } from 'vue';
import { Temporal } from '@js-temporal/polyfill';
import { useI18n } from 'vue-i18n';
import { optionAfter, optionBefore } from '@/utils/duration';

const { t } = useI18n();
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  dropdownSignOptions: {
    type: Array,
    required: false,
    default: () => []
  },
  canBeNegative: {
    type: Boolean,
    required: false,
    default: false
  },
  modelValue: {
    type: String,
    required: false,
    default: undefined
  }
});
const { value: cutoffTimeInTemporalFormat, handleChange } = useField<string>(
  () => props.name,
  props.rules,
  {
    initialValue: props.modelValue
  }
);

const signValue = computed(() => {
  const temporalString = cutoffTimeInTemporalFormat.value;
  if (!temporalString) {
    return 1;
  }
  return temporalString.startsWith('-') ? -1 : 1;
});
const localCutoffTimeNumericFormat = computed(() => {
  const temporalString = cutoffTimeInTemporalFormat.value;
  if (!temporalString) {
    return {
      hours: 0,
      minutes: 0
    };
  }
  // In order to display the value we remove the sign, and use sign value in the dropdown
  const temporalStringWithoutSign = temporalString.replace('-', '');

  // More info on temporal duration balancing:
  // https://tc39.es/proposal-temporal/docs/balancing.html#balancing-durations-with-round
  const temporal = Temporal.Duration.from(temporalStringWithoutSign);
  const rounded = temporal.round({ largestUnit: 'hour', smallestUnit: 'minute' });
  return {
    hours: rounded.hours,
    minutes: rounded.minutes
  };
});

const onChangeSign = (signValue: -1 | 1) => {
  const { hours, minutes } = localCutoffTimeNumericFormat.value;
  createTemporalStringAndEmitToParent(hours, minutes, signValue);
};

const onChangeHours = (hours: number) => {
  // Use current minutes value
  const { minutes } = localCutoffTimeNumericFormat.value;
  const sign = signValue.value;
  createTemporalStringAndEmitToParent(hours, minutes, sign);
};
const onChangeMinutes = (minutes: number) => {
  // Take current hour value
  const { hours } = localCutoffTimeNumericFormat.value;
  const sign = signValue.value;
  createTemporalStringAndEmitToParent(hours, minutes, sign);
};

const createTemporalStringAndEmitToParent = (hours: number, minutes: number, sign: -1 | 1) => {
  const hoursWithSign = sign * hours;
  const minutesWithSign = sign * minutes;
  // Create a string in a following format: "PT1H20M" or "PT1H"
  const hoursAndMinutesTemporal = Temporal.Duration.from({
    hours: hoursWithSign,
    minutes: minutesWithSign
  })
    // More info on temporal duration balancing:
    // https://tc39.es/proposal-temporal/docs/balancing.html#balancing-durations-with-round
    .round({ smallestUnit: 'minute' })
    .toString();
  // Emit change to the parent component
  handleChange(hoursAndMinutesTemporal);
};

const defaultSignOptions = computed(() => {
  return [optionAfter(), optionBefore()];
});
</script>
