<template>
  <Panel :header="t('portal_pricing_default_pricing')">
    <div
      v-for="(item, index) of fields"
      :key="index"
      class="flex justify-content-between flex-wrap"
    >
      <div class="flex-initial flex flex-column">
        <p class="font-bold mb-0">
          {{ getPricingCategory(item.value, 'display_name') }}
        </p>
        <p>
          {{ t('portal_pricing_age_range') }}: {{ getPricingCategory(item.value, 'min_age') }} -
          {{ getPricingCategory(item.value, 'max_age') }}
        </p>
      </div>
      <div class="flex-initial flex align-items-center justify-content-center">
        <Field v-slot="{ value, handleChange }" :name="`${name}[${index}].value`">
          <InputNumber
            :model-value="value"
            :currency="getCurrencyCode"
            mode="currency"
            :min-fraction-digits="getNumFractionDigits"
            :locale="getSelectedLocale"
            :disabled="disabled"
            @update:model-value="handleChange"
          ></InputNumber>
        </Field>
      </div>
      <Divider v-if="index !== pricingCategories.length - 1" />
    </div>
  </Panel>
</template>

<script lang="ts" setup>
import type {
  PricingByIdReadResponsePricingPricingCategoryPricesInner,
  PricingCategoriesReadResponsePricingCategoriesInner
} from '@openapi/models';
import { Field, type FieldEntry } from 'vee-validate';
import { toRefs, type PropType } from 'vue';
import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
import { useI18n } from 'vue-i18n';
import InputNumber from 'primevue/inputnumber';
import { useCurrencyStore } from '@/stores/currencies';
import { useLocaleStore } from '@/stores/locales';

const { t } = useI18n();
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: 'required'
  },
  fields: {
    type: Array as PropType<FieldEntry<PricingByIdReadResponsePricingPricingCategoryPricesInner>[]>,
    required: false,
    default: () => []
  },
  pricingCategories: {
    type: Array as PropType<PricingCategoriesReadResponsePricingCategoriesInner[]>,
    required: true,
    default: () => []
  },
  disabled: {
    type: Boolean,
    required: false,
    default: () => false
  }
});
const { getCurrencyCode, getNumFractionDigits } = useCurrencyStore();
const { getSelectedLocale } = useLocaleStore();
const { name, pricingCategories, fields } = toRefs(props);

const getPricingCategory = (
  fieldEntry: PricingByIdReadResponsePricingPricingCategoryPricesInner,
  field: keyof PricingCategoriesReadResponsePricingCategoriesInner
) => {
  const category = pricingCategories.value.find(
    (item) => item.id === fieldEntry.pricing_category_id
  );
  return category ? category[field] : '';
};
</script>
