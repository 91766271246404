import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { bootstrapApp } from '@/bootstrap';
import App from './App.vue';
import router from './router';

import PrimeVue from 'primevue/config';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import InputText from 'primevue/inputtext';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Textarea from 'primevue/textarea';
import i18n from '@/i18n';
import PostHog from '@/plugins/posthog';
import Sanitize from '@/plugins/sanitize';

//Validation
import DropdownValidation from '@/components/forms/DropdownValidation.vue';
import CheckboxValidation from '@/components/forms/CheckboxValidation.vue';
import ColorPickerValidation from '@/components/forms/ColorPickerValidation.vue';
import FileSingleUploadValidation from '@/components/forms/FileSingleUploadValidation.vue';
import FileMultipleUploadValidation from '@/components/forms/FileMultipleUploadValidation.vue';
import CalendarValidation from '@/components/forms/CalendarValidation.vue';
import CalendarModeValidation from '@/components/forms/CalendarModeValidation.vue';
import InputTemporalDurationValidation from '@/components/forms/InputTemporalDuration.vue';
import WeekdaysValidation from '@/components/forms/WeekdaysValidation.vue';
import PricingCategoryPricesValidation from '@/components/pricing/PricingCategoryPricesValidation.vue';
import AddonPricesValidation from './components/pricing/AddonPricesValidation.vue';
import InputNumberValidation from '@/components/forms/InputNumberValidation.vue';
import RadioGroupValidation from '@/components/forms/RadioGroupValidation.vue';
import InputTimeValidation from '@/components/forms/InputTimeValidation.vue';
import InputDateTimeValidation from '@/components/forms/InputDateTimeValidation.vue';
import InputTextValidation from '@/components/forms/InputTextValidation.vue';
import InputSwitchValidation from '@/components/forms/InputSwitchValidation.vue';
import MultiSelectValidation from '@/components/forms/MultiSelectValidation.vue';
import EditorValidation from '@/components/forms/EditorValidation.vue';
import SelectButtonValidation from './components/forms/SelectButtonValidation.vue';
import InlineMessage from 'primevue/inlinemessage';
import MultiSelect from 'primevue/multiselect';

// Import all styles (primevue, sakai, custom gyg theme)
import '@/assets/styles.scss';
import { FetchError } from '@openapi/runtime';
import FocusTrap from 'primevue/focustrap';

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.use(PostHog);
app.use(Sanitize);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('focustrap', FocusTrap);

// Validation
app.component('DropdownValidation', DropdownValidation);
app.component('CheckboxValidation', CheckboxValidation);
app.component('ColorPickerValidation', ColorPickerValidation);
app.component('FileSingleUploadValidation', FileSingleUploadValidation);
app.component('FileMultipleUploadValidation', FileMultipleUploadValidation);
app.component('CalendarValidation', CalendarValidation);
app.component('CalendarModeValidation', CalendarModeValidation);
app.component('WeekdaysValidation', WeekdaysValidation);
app.component('PricingCategoryPricesValidation', PricingCategoryPricesValidation);
app.component('AddonPricesValidation', AddonPricesValidation);
app.component('InputNumberValidation', InputNumberValidation);
app.component('RadioGroupValidation', RadioGroupValidation);
app.component('InputText', InputText);
app.component('InputTemporalDurationValidation', InputTemporalDurationValidation);
app.component('InputTimeValidation', InputTimeValidation);
app.component('InputDateTimeValidation', InputDateTimeValidation);
app.component('InputTextValidation', InputTextValidation);
app.component('InputSwitchValidation', InputSwitchValidation);
app.component('MultiSelectValidation', MultiSelectValidation);
app.component('MultiSelect', MultiSelect);
app.component('EditorValidation', EditorValidation);
app.component('SelectButtonValidation', SelectButtonValidation);

// eslint-disable-next-line vue/no-reserved-component-names
app.component('Textarea', Textarea);
app.component('InlineMessage', InlineMessage);

app.config.errorHandler = function (err) {
  if ((err instanceof DOMException && err.name === 'AbortError') || err instanceof FetchError) {
    // The request was aborted by the postMiddleware (PARTNER_NOT_FOUND ExpectedException); this situation also leads to
    // FetchErrors: Catching and dropping both types of errors
  }
};

bootstrapApp(app).then(() => app.mount('#app'));
