<template>
  <section class="flex flex-1 flex-column">
    <div class="message-list">
      <ul ref="messageList">
        <li v-for="(message, index) in messages" :key="index" class="mb-4">
          <strong v-if="message.isPartner">{{ getName }}</strong>
          <i v-if="!message.text" class="pi pi-spin pi-spinner mr-2"></i>
          <strong v-if="!message.isPartner">AI ChatBot</strong>
          <br />
          {{ message.text }}
        </li>
      </ul>
    </div>

    <div>
      <ul class="list-none p-0">
        <li v-for="(suggestion, idx) of suggestions" :key="idx">
          <a
            class="cursor-pointer flex surface-border mb-2 p-2 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            @click="chooseSelection(suggestion)"
          >
            <p class="text-color-secondary text-sm m-0">{{ suggestion }}</p>
          </a>
        </li>
      </ul>
      <div class="input-area">
        <InputText
          v-model="newMessage"
          class="flex-1"
          placeholder="Type a message..."
          @keyup.enter="sendMessage"
        />
        <Button label="Send" @click="sendMessage" />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useUserStore } from '@/stores/user';
import useAiAnalyst from '@/composables/use-ai-analyst';

const suggestions = ref([
  'How many bookings did I have last month?',
  'What is my total revenue?',
  'What is the average order value?'
]);
const messageList = ref();
const newMessage = ref('');
const messages = ref([{ text: 'Hello, how can I help you today?', isPartner: false }]);
const { getName } = useUserStore();
const { askQuestion } = useAiAnalyst();

const sendMessage = async () => {
  if (newMessage.value.trim()) {
    messages.value.push({ text: newMessage.value, isPartner: true });
    const botMessage = ref('');
    messages.value.push({ text: botMessage as unknown as string, isPartner: false });

    try {
      const answer = await askQuestion(newMessage.value);
      botMessage.value = answer || 'Sorry, I was not able to process your request';
      messageList.value.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    } catch (error) {
      botMessage.value = 'Sorry, I was not able to process your request';
    }
    newMessage.value = '';
  }
};

function chooseSelection(suggestion: string) {
  suggestions.value = [];
  newMessage.value = suggestion;
}
</script>

<style>
.message-list {
  flex-grow: 1;
  overflow-y: auto;
}

.message-list ul {
  list-style-type: none;
  padding: 0;
}

.message-list li {
  margin-bottom: 10px;
}

.user-message {
  text-align: right;
}

.bot-message {
  text-align: left;
}

.input-area {
  display: flex;
  gap: 10px;
}
</style>
