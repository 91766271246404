<template>
  <Field v-slot="{ handleChange, value }" :name="name" :rules="rules">
    <SelectButton
      :model-value="value"
      :options="options"
      :option-label="optionLabel"
      :option-value="optionValue"
      :option-disabled="optionDisabled"
      :multiple="multiple"
      :invalid="invalid"
      :disabled="disabled"
      :data-key="dataKey"
      :unselectable="unselectable"
      :allow-empty="allowEmpty"
      :aria-labelledby="ariaLabelledby"
      @update:model-value="handleChange"
    >
      <template #option="slotProps">
        <i :class="slotProps.option.icon"></i>
        <span class="ml-2">{{ slotProps.option.label }}</span>
      </template>
    </SelectButton>

    <!-- display help text if condition is true -->
    <div v-if="helpTextCondition">
      <InlineMessage severity="info">{{ helpTextLabel }}</InlineMessage>
    </div>
  </Field>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { toRefs, type PropType } from 'vue';
import SelectButton from 'primevue/selectbutton';
import InlineMessage from 'primevue/inlinemessage';

const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  options: {
    type: Array as PropType<{ label: string; value: string; icon?: string }[]>,
    required: true,
    default: () => []
  },
  optionLabel: {
    type: String,
    required: false,
    default: null
  },
  optionValue: {
    type: String,
    required: false,
    default: null
  },
  optionDisabled: {
    type: String,
    required: false,
    default: null
  },
  multiple: {
    type: Boolean,
    required: false,
    default: false
  },
  invalid: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  dataKey: {
    type: String,
    required: false,
    default: null
  },
  unselectable: {
    type: Boolean,
    required: false,
    default: true
  },
  allowEmpty: {
    type: Boolean,
    required: false,
    default: true
  },
  ariaLabelledby: {
    type: String,
    required: false,
    default: null
  },
  helpTextCondition: {
    type: Boolean,
    required: false,
    default: false
  },
  helpTextLabel: {
    type: String,
    required: false,
    default: ''
  }
});

const {
  name,
  rules,
  options,
  optionLabel,
  optionValue,
  optionDisabled,
  multiple,
  invalid,
  disabled,
  dataKey,
  unselectable,
  allowEmpty,
  ariaLabelledby,
  helpTextCondition,
  helpTextLabel
} = toRefs(props);
</script>
