<template>
  <div class="flex align-items-center">
    <Field v-slot="{ handleChange, value }" :name="name" :rules="rules">
      <Listbox
        v-model="dayOfWeek"
        :options="options"
        option-label="label"
        option-value="value"
        multiple
        aria-labelledby="multiple"
        class="w-full"
        @change="onChange($event, value, handleChange)"
      />
    </Field>
    <label :for="name" class="ml-2">{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
import { Field } from 'vee-validate';
import { ref, toRefs } from 'vue';
import Listbox from 'primevue/listbox';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  helpText: {
    type: String,
    required: false,
    default: null
  },
  label: {
    type: String,
    required: false,
    default: null
  },
  placeholder: {
    type: String,
    required: false,
    default: null
  }
});
type days = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

const { name, rules, label } = toRefs(props);
const dayOfWeek = ref(null);
const options = ref([
  { value: 'monday', label: t('portal_monday') },
  { value: 'tuesday', label: t('portal_tuesday') },
  { value: 'wednesday', label: t('portal_wednesday') },
  { value: 'thursday', label: t('portal_thursday') },
  { value: 'friday', label: t('portal_friday') },
  { value: 'saturday', label: t('portal_saturday') },
  { value: 'sunday', label: t('portal_sunday') }
]);

const onChange = (
  { value }: { value: string[] },
  modelValue: days,
  handleChange: (value: unknown) => void
) => {
  const lowerCaseDayValues = value.map((day) => day.toLowerCase());
  const updatedValues = { ...modelValue };
  for (const day in updatedValues) {
    updatedValues[day as keyof days] = lowerCaseDayValues.includes(day);
  }
  handleChange(updatedValues);
};
</script>
