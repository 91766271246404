<template>
  <Sidebar v-model:visible="visible" position="right">
    <div class="flex flex-column mx-auto md:mx-0">
      <h4 class="mb-2 font-semibold">Welcome</h4>
      <span class="text-color-secondary font-medium mb-5">
        {{ getFirstName }} {{ getLastName }}
      </span>
      <ul class="list-none m-0 p-0">
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            @click="onSettingsClick"
          >
            <span><i class="pi pi-cog text-xl text-primary"></i></span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">{{ t('portal_settings') }}</span>
              <p class="text-color-secondary m-0">{{ t('portal_view_profile') }}</p>
            </div>
          </a>
        </li>
        <!--  <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span><i class="pi pi-cog text-xl text-primary"></i></span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Settings</span>
              <p class="text-color-secondary m-0">Account settings</p>
            </div>
          </a>
        </li> -->
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            @click="onSignOutClick"
          >
            <span><i class="pi pi-power-off text-xl text-primary"></i></span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">{{ t('portal_sign_out') }}</span>
              <p class="text-color-secondary m-0">{{ t('portal_sign_out_sub') }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="flex flex-column mt-5 mx-auto md:mx-0">
      <h4 class="mb-2 font-semibold">{{ t('portal_notifications') }}</h4>
      <span class="text-color-secondary font-medium mb-5">{{ notificationCount }}</span>
      <!-- <ul class="list-none m-0 p-0">
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span><i class="pi pi-comment text-xl text-primary"></i></span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Your post has new comments</span>
              <p class="text-color-secondary m-0">5 min ago</p>
            </div>
          </a>
        </li>
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span><i class="pi pi-trash text-xl text-primary"></i></span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Your post has been deleted</span>
              <p class="text-color-secondary m-0">15min ago</p>
            </div>
          </a>
        </li>
        <li>
          <a
            class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
          >
            <span><i class="pi pi-folder text-xl text-primary"></i></span>
            <div class="ml-3">
              <span class="mb-2 font-semibold">Post has been updated</span>
              <p class="text-color-secondary m-0">3h ago</p>
            </div>
          </a>
        </li>
      </ul> -->
    </div>
    <div class="flex flex-column mt-5 mx-auto md:mx-0 text-500 align-self-end">
      <Impersonate />
      <Button
        disabled
        severity="secondary"
        text
        class="mt-3"
        size="small"
        :label="`version: ${PORTAL_RELEASE_VERSION}`"
        @click="onTriggerError"
      />
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
import { directus } from '@/service/directus';
import { useUserStore } from '@/stores/user';
import Sidebar from 'primevue/sidebar';
import { computed, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { ROUTE_NAMES } from '@/constants/routes';
import { DIRECTUS_API_URL, PORTAL_RELEASE_VERSION } from '@/config';
import { DEFAULT_LOCALE } from '@/constants/i18n';
import { useAuth } from '@/composables/use-auth';
import Impersonate from '@/components/auth/Impersonate.vue';
import Button from 'primevue/button';

const { t } = useI18n();
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
    default: false
  }
});
const { isVisible } = toRefs(props);
const visible = ref(false);
const router = useRouter();
const { getFirstName, getLastName, isGygAdminUser } = useUserStore();
const { updateUserRole } = useAuth();
const toast = useToast();
watch(isVisible, (value) => {
  visible.value = value;
});
const notificationCount = computed(() => t('portal_notifications_count').replace('%s', '0'));

const onSignOutClick = async () => {
  if (isGygAdminUser) {
    await updateUserRole({
      rolesUpdateRequest: {
        locale_code: DEFAULT_LOCALE
      }
    });
  }
  await directus.logout();
  // Removes the auth_expires_at from the local storage after successful sign out
  // It's necessary to do that manually because sdk doesn't remove it automatically
  window.localStorage.removeItem('auth_expires_at');
  toast.add({
    severity: 'success',
    summary: t('portal_success_title'),
    detail: t('portal_sign_out_success_text'),
    life: 10000
  });
  await router.push({ name: ROUTE_NAMES.LOGIN });
};
const onSettingsClick = async () => {
  await router.push({ name: ROUTE_NAMES.PROFILE });
  visible.value = false;
};

const onTriggerError = async () => {
  await fetch(`${DIRECTUS_API_URL}/infrastructure/_error`, { method: 'GET' });
  throw new Error('Test error triggered');
};
</script>
