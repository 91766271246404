/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayoutByIdReadResponsePayoutPartner } from './PayoutByIdReadResponsePayoutPartner';
import {
  PayoutByIdReadResponsePayoutPartnerFromJSON,
  PayoutByIdReadResponsePayoutPartnerFromJSONTyped,
  PayoutByIdReadResponsePayoutPartnerToJSON
} from './PayoutByIdReadResponsePayoutPartner';

/**
 *
 * @export
 * @interface PayoutByIdReadResponsePayout
 */
export interface PayoutByIdReadResponsePayout {
  /**
   *
   * @type {Date}
   * @memberof PayoutByIdReadResponsePayout
   */
  created_at: Date;
  /**
   * End date of the current period in ISO 8601 date format
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  end_date: string;
  /**
   * Payout ID
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  id: string;
  /**
   * Amount paid out for the period
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  payout_amount: string;
  /**
   * Reference of the payout
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  payout_reference: string;
  /**
   * Amount of refunds for the period
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  returns_amount: string;
  /**
   * Deposit amount retained for the period
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  risk_deposit_amount: string;
  /**
   * Deposit amount retained for the period
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  risk_deposit_previous_period_amount: string;
  /**
   * Start date of the current period in ISO 8601 date format
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  start_date: string;
  /**
   * Status of the payout
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  status: PayoutByIdReadResponsePayoutStatusEnum;
  /**
   * Total revenue transacted in the period by this partner
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  total_transacted_revenue_amount: string;
  /**
   * Total commission earned by the partner in the period
   * @type {string}
   * @memberof PayoutByIdReadResponsePayout
   */
  total_commission_amount: string;
  /**
   *
   * @type {PayoutByIdReadResponsePayoutPartner}
   * @memberof PayoutByIdReadResponsePayout
   */
  partner: PayoutByIdReadResponsePayoutPartner;
}

/**
 * @export
 */
export const PayoutByIdReadResponsePayoutStatusEnum = {
  Requested: 'requested',
  Pending: 'pending',
  TransferSentOut: 'transfer_sent_out',
  Failed: 'failed'
} as const;
export type PayoutByIdReadResponsePayoutStatusEnum =
  (typeof PayoutByIdReadResponsePayoutStatusEnum)[keyof typeof PayoutByIdReadResponsePayoutStatusEnum];

/**
 * Check if a given object implements the PayoutByIdReadResponsePayout interface.
 */
export function instanceOfPayoutByIdReadResponsePayout(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'end_date' in value;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'payout_amount' in value;
  isInstance = isInstance && 'payout_reference' in value;
  isInstance = isInstance && 'returns_amount' in value;
  isInstance = isInstance && 'risk_deposit_amount' in value;
  isInstance = isInstance && 'risk_deposit_previous_period_amount' in value;
  isInstance = isInstance && 'start_date' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'total_transacted_revenue_amount' in value;
  isInstance = isInstance && 'total_commission_amount' in value;
  isInstance = isInstance && 'partner' in value;

  return isInstance;
}

export function PayoutByIdReadResponsePayoutFromJSON(json: any): PayoutByIdReadResponsePayout {
  return PayoutByIdReadResponsePayoutFromJSONTyped(json, false);
}

export function PayoutByIdReadResponsePayoutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutByIdReadResponsePayout {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    created_at: new Date(json['created_at']),
    end_date: json['end_date'],
    id: json['id'],
    payout_amount: json['payout_amount'],
    payout_reference: json['payout_reference'],
    returns_amount: json['returns_amount'],
    risk_deposit_amount: json['risk_deposit_amount'],
    risk_deposit_previous_period_amount: json['risk_deposit_previous_period_amount'],
    start_date: json['start_date'],
    status: json['status'],
    total_transacted_revenue_amount: json['total_transacted_revenue_amount'],
    total_commission_amount: json['total_commission_amount'],
    partner: PayoutByIdReadResponsePayoutPartnerFromJSON(json['partner'])
  };
}

export function PayoutByIdReadResponsePayoutToJSON(
  value?: PayoutByIdReadResponsePayout | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    created_at: value.created_at.toISOString(),
    end_date: value.end_date,
    id: value.id,
    payout_amount: value.payout_amount,
    payout_reference: value.payout_reference,
    returns_amount: value.returns_amount,
    risk_deposit_amount: value.risk_deposit_amount,
    risk_deposit_previous_period_amount: value.risk_deposit_previous_period_amount,
    start_date: value.start_date,
    status: value.status,
    total_transacted_revenue_amount: value.total_transacted_revenue_amount,
    total_commission_amount: value.total_commission_amount,
    partner: PayoutByIdReadResponsePayoutPartnerToJSON(value.partner)
  };
}
