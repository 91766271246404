/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BusinessInfoPartnerTranslationsInner } from './BusinessInfoPartnerTranslationsInner';
import {
  BusinessInfoPartnerTranslationsInnerFromJSON,
  BusinessInfoPartnerTranslationsInnerFromJSONTyped,
  BusinessInfoPartnerTranslationsInnerToJSON
} from './BusinessInfoPartnerTranslationsInner';
import type { BusinessInformationReadResponsePartnerTax } from './BusinessInformationReadResponsePartnerTax';
import {
  BusinessInformationReadResponsePartnerTaxFromJSON,
  BusinessInformationReadResponsePartnerTaxFromJSONTyped,
  BusinessInformationReadResponsePartnerTaxToJSON
} from './BusinessInformationReadResponsePartnerTax';

/**
 *
 * @export
 * @interface BusinessInformationUpdateRequest
 */
export interface BusinessInformationUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  business_name?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  default_locale: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  default_timezone: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  business_registration_number?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  tax_identification_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  phone_nr: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  phone_nr_public?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  logo_file_id: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  backlink_url: string;
  /**
   *
   * @type {boolean}
   * @memberof BusinessInformationUpdateRequest
   */
  show_subscribe_to_newsletter?: boolean;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateRequest
   */
  country_code: string;
  /**
   *
   * @type {BusinessInformationReadResponsePartnerTax}
   * @memberof BusinessInformationUpdateRequest
   */
  tax?: BusinessInformationReadResponsePartnerTax;
  /**
   *
   * @type {Array<BusinessInfoPartnerTranslationsInner>}
   * @memberof BusinessInformationUpdateRequest
   */
  partner_translation: Array<BusinessInfoPartnerTranslationsInner>;
}

/**
 * Check if a given object implements the BusinessInformationUpdateRequest interface.
 */
export function instanceOfBusinessInformationUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'currency' in value;
  isInstance = isInstance && 'default_locale' in value;
  isInstance = isInstance && 'default_timezone' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'phone_nr' in value;
  isInstance = isInstance && 'logo_file_id' in value;
  isInstance = isInstance && 'backlink_url' in value;
  isInstance = isInstance && 'country_code' in value;
  isInstance = isInstance && 'partner_translation' in value;

  return isInstance;
}

export function BusinessInformationUpdateRequestFromJSON(
  json: any
): BusinessInformationUpdateRequest {
  return BusinessInformationUpdateRequestFromJSONTyped(json, false);
}

export function BusinessInformationUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessInformationUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    name: json['name'],
    business_name: !exists(json, 'business_name') ? undefined : json['business_name'],
    currency: json['currency'],
    status: !exists(json, 'status') ? undefined : json['status'],
    default_locale: json['default_locale'],
    default_timezone: json['default_timezone'],
    business_registration_number: !exists(json, 'business_registration_number')
      ? undefined
      : json['business_registration_number'],
    tax_identification_number: !exists(json, 'tax_identification_number')
      ? undefined
      : json['tax_identification_number'],
    email: json['email'],
    phone_nr: json['phone_nr'],
    phone_nr_public: !exists(json, 'phone_nr_public') ? undefined : json['phone_nr_public'],
    logo_file_id: json['logo_file_id'],
    backlink_url: json['backlink_url'],
    show_subscribe_to_newsletter: !exists(json, 'show_subscribe_to_newsletter')
      ? undefined
      : json['show_subscribe_to_newsletter'],
    country_code: json['country_code'],
    tax: !exists(json, 'tax')
      ? undefined
      : BusinessInformationReadResponsePartnerTaxFromJSON(json['tax']),
    partner_translation: (json['partner_translation'] as Array<any>).map(
      BusinessInfoPartnerTranslationsInnerFromJSON
    )
  };
}

export function BusinessInformationUpdateRequestToJSON(
  value?: BusinessInformationUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    business_name: value.business_name,
    currency: value.currency,
    status: value.status,
    default_locale: value.default_locale,
    default_timezone: value.default_timezone,
    business_registration_number: value.business_registration_number,
    tax_identification_number: value.tax_identification_number,
    email: value.email,
    phone_nr: value.phone_nr,
    phone_nr_public: value.phone_nr_public,
    logo_file_id: value.logo_file_id,
    backlink_url: value.backlink_url,
    show_subscribe_to_newsletter: value.show_subscribe_to_newsletter,
    country_code: value.country_code,
    tax: BusinessInformationReadResponsePartnerTaxToJSON(value.tax),
    partner_translation: (value.partner_translation as Array<any>).map(
      BusinessInfoPartnerTranslationsInnerToJSON
    )
  };
}
