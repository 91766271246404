import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const PROMOCODES_ROUTES = [
  {
    path: 'sell/promo-codes',
    name: ROUTE_NAMES.PROMO_CODES,
    component: () => import('@/views/pages/sell/PromoCodes.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.PROMO_CODES],
      label: PAGE_TITLES.PROMO_CODES,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.PromoCodeView],
      icon: 'pi pi-fw pi-percentage'
    }
  },
  {
    path: 'sell/promo-codes/+',
    name: ROUTE_NAMES.NEW_PROMO_CODE,
    component: () => import('@/views/pages/sell/PromoCodeNew.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.PROMO_CODES, PAGE_TITLES.NEW_PROMO_CODE]
    }
  },
  {
    path: 'sell/promo-codes/:promoCodeId',
    name: ROUTE_NAMES.EDIT_PROMO_CODE,
    component: () => import('@/views/pages/sell/PromoCode.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.PROMO_CODES, PAGE_TITLES.EDIT_PROMO_CODE]
    }
  },
  {
    path: `/sell/${ROUTE_NAMES.BOOKING}/:bookingId`,
    name: ROUTE_NAMES.BOOKING,
    component: () => import('@/views/pages/sell/Booking.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.BOOKINGS, PAGE_TITLES.BOOKING]
    }
  }
];
